.wrapperModal {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.sm {
  width: 500px;
  max-width: 500px;
}

.lg {
  width: 800px;
  max-width: 800px;
}

.xl {
  width: 70vw;
  max-width: 1200px;
}

.full {
  width: 100% !important;
  height: 100vh;
  max-height: 100vh;
}

@media (max-width: 900px) {
  .sm {
    width: 100%;
    min-height: 100vh;
  }

  .lg {
    width: 100%;
    min-height: 100vh;
  }

  .xl {
    /* width: 100%; */
    min-height: 100vh;
  }

  .full {
    width: 100%;
    min-height: 100vh;
  }
}

.modalHeader {
  border-bottom: 1px solid #dee2e6;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: "#181C3";
}

.modalBody {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding: 20px;
  height: 100%;
  overflow: auto;
}

.modalFooter {
  width: 100%;
  padding: 10px;
  border-top: 1px solid #dee2e6;
  display: flex-end;
  gap: 10px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

$primary: rebeccapurple;
$danger: #dd0000;
$success: lime;
$warning: orange;
$info: aqua;

$body-bg: #f3f3f0;

.export-btn {
  background: transparent;
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-lg {
  width: 121px;
}

.card-header {
  border-bottom: 0px !important;
}

.disable-button-login {
  opacity: 1 !important;
}

.form-control-label {
  font-size: 16px !important;
}

.card-login {
  width: 33.75rem !important;
  height: 38.188rem !important;
  display: flex !important;
  align-items: center !important;
}

.alert-login-singin {
  height: 2.813rem !important;
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.alert-login-forgotten {
  height: 4.375rem !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  margin-top: 2.5rem !important;
}

.card-login-confirm {
  width: 33.75rem !important;
  height: 38.188rem !important;
}

.wapper-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper-img {
  display: flex !important;
  justify-content: center !important;
  padding-top: 1.875rem !important;
  height: 3.375rem !important;
}

.confirmation-img {
  display: flex !important;
  align-self: flex-start !important;
  margin-left: 2.5rem !important;
}

.login-inputs {
  height: 2.875rem !important;
  width: 21.813rem !important;
  display: flex !important;
  align-items: center !important;
}

.input-error::placeholder {
  color: #f75676 !important;
}

.is-invalid {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

@media screen and (min-width: 1500px) {
  .row-login {
    margin-top: 20rem !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1500px) {
  .row-login {
    margin-top: 11rem !important;
    margin-right: 8.125rem !important;
  }
  .bg-gradient-branco {
    height: 20rem !important;
    width: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .row-login {
    margin-top: 12em !important;
  }
  .card-login {
    width: 24rem !important;
    height: 37.188rem !important;
    display: flex !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .login-inputs {
    height: 3.3rem !important;
    width: 20rem !important;
  }
  .wrapper-login {
    margin-left: 2rem !important;
    width: 100% !important;
  }
  .wrapper-body {
    width: 22.5rem !important;
    size: 6.25rem !important;
    height: 25rem !important;
  }
  .wrapper-img {
    height: 2.5rem !important;
  }
  .first-img {
    height: 3.75rem !important;
  }
  .second-img {
    height: 3.75rem !important;
  }
  .components-login {
    height: 3.125rem !important;
  }
  .my-4 {
    width: 11.25rem !important;
  }
  .wrapper-img {
    margin-bottom: 1.563rem !important;
  }
  .notification {
    height: 3.3rem !important;
    width: 20rem !important;
    color: #ffffff !important;
  }
  .bg-gradient-branco {
    width: 37.375rem !important;
    height: 20rem !important;
  }
}

@media print {
  body * {
    visibility: hidden;
    background-color: red !important;
  }

  #divToPrint {
    width: 100%;
  }
}

@import "bootstrap";

.css-1s2u09g-control {
  min-height: 45px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-file {
  z-index: 0 !important;
}
.custom-file-input:lang(pt-BR) ~ .custom-file-label::after {
  content: "Buscar";
}
.custom-file-label {
  color: hsl(0, 0%, 20%) !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}
.custom-file-label:hover {
  border-color: #5e72e4 !important;
  box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1);
}

.form-select {
  font-weight: bold !important;
  font-size: 1rem !important;
  color: hsl(0, 0%, 20%) !important;
}

/* CSS - GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient-azul {
  background: linear-gradient(87deg, #2255f9 0, #2255f9 100%) !important;
}

.bg-gradient-azul-escuro {
  background: linear-gradient(87deg, #1f1e53 0, #1f1e53 100%) !important;
}

.bg-gradient-branco {
  background: linear-gradient(87deg, #f7fafc 0, #f7fafc 100%) !important;
  width: 1em;
}

.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: #2c3e50;
  z-index: 0;
  transform: skewY(-2deg);
  transform-origin: top left;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e9edf1;
}

.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #f7fafc;
  height: 50vh;
  z-index: 0;
  transform: skewY(-4deg);
  transform-origin: top left;
}
